import {downloadByGet, get, post} from '@/libs/axios'

/** SaaS部署 */
export const saveSassEnterprise = params => {
  const submitUrl = params.id ? '/app/saasEnterprise/UpdateEntity' : '/app/saasEnterprise/CreateEntity'
  return post(submitUrl, params)
}
export const delSassEnterprise = params => post('/app/saasEnterprise/DeleteEntityByIds', params)
export const getSassEnterprise = params => post('/app/saasEnterprise/GetDetails', params)
export const getSassEnterprises = params => post('/app/saasEnterprise/Page', params)
export const authSassEnterprises = params => post('/app/saasEnterprise/Authorization', params)
export const updateSassEnterprisesStatus = params => post('/app/saasEnterprise/UpdateEntityStatus', params)
export const saveSassEnterpriseRole = params => {
  const submitUrl = params.id ? '/app/saasEnterprise/UpdateRole' : '/app/saasEnterprise/CreateRole'
  return post(submitUrl, params)
}
/** 独立部署 */
export const saveAutonomyEnterprise = params => {
  const submitUrl = params.id ? '/app/autonomyEnterprise/UpdateEntity' : '/app/autonomyEnterprise/CreateEntity'
  return post(submitUrl, params)
}
export const delAutonomyEnterprise = params => post('/app/autonomyEnterprise/DeleteEntityByIds', params)
export const getAutonomyEnterprise = params => post('/app/autonomyEnterprise/GetDetails', params)
export const getAutonomyEnterprises = params => post('/app/autonomyEnterprise/Page', params)
export const authAutonomyEnterprises = params => post('/app/autonomyEnterprise/Authorization', params)
export const updatetAutonomyEnterpriseStatus = params => post('/app/autonomyEnterprise/UpdateEntityStatus', params)
export const saveAutonomyEnterpriseRole = params => {
  const submitUrl = params.id ? '/app/autonomyEnterprise/UpdateRole' : '/app/autonomyEnterprise/CreateRole'
  return post(submitUrl, params)
}


export const delAutoRole = params => post('/app/autonomyEnterprise/DeleteRole', params)
export const delSassRole = params => post('/app/saasEnterprise/DeleteRole', params)
export const getAutoRoleResources = params => post('/app/autonomyEnterprise/GetRoleResourceCodes', params)
export const getSassRoleResources = params => post('/app/saasEnterprise/GetRoleResourceCodes', params)
export const getAutoRoleList = params => post('/app/autonomyEnterprise/GetRoleList', params)
export const getSassRoleList = params => post('/app/saasEnterprise/GetRoleList', params)
export const getResources = () => get('/sys/app/ListSysResource')
export const UploadLogo = params => post('/sys/enterprise/UploadLogo', params)
export const getTemplates = () => get('/template/GetAllList')
export const getAssessTemplats = () => get('/assess/template/GetAllAssessTemplate')
export const DownloadLicenseFile = () => downloadByGet(`/app/autonomyEnterprise/DownloadLicenseFile?_t=${Date.parse(new Date())}`)

export const ApplyTrialPage = params => post('/website/ApplyTrial/Page', params)
export const ApplyTrialDel = params => post('/website/ApplyTrial/Delete', params)
export const ApplyTrialProcess = params => post('/website/ApplyTrial/Process', params)
export const ProposePage = params => post('/website/propose/Page', params)
export const ProposeDel = params => post('/website/propose/Delete', params)
export const ProposeProcess = params => post('/website/propose/Process', params)