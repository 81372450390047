<template>
  <el-card>
    <el-row
      class="filter-wrap"
      :gutter="10"
    >
      <el-col :span="4">
        <div class="flex-start">
          <el-input
            v-model="listQuery.searchStr"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
        </div>
      </el-col>
      <el-col :span="3">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </el-col>
      <el-col
        :span="17"
        class="text-right"
      >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="gotoEnterprise(0, 'edit')"
        >
          新增
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="企业简称"
        :show-overflow-tooltip="true"
        prop="shortName"
      />
      <el-table-column
        prop="name"
        :show-overflow-tooltip="true"
        label="企业全称"
      />
      <el-table-column
        prop="contactName"
        :show-overflow-tooltip="true"
        label="联系人"
      />
      <el-table-column
        prop="contactPhone"
        label="电话"
      >
        <template slot-scope="{row}">
          {{ row.contactPhone ? hideTel(row.contactPhone) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="effectiveTime"
        :show-overflow-tooltip="true"
        label="有效期"
      >
        <template slot-scope="{row}">
          {{ row.effectiveTime || '无限制' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="adminAccount"
        :show-overflow-tooltip="true"
        label="管理员账号"
      />
      <el-table-column
        prop="machineCode"
        :show-overflow-tooltip="true"
        label="机器码"
      />
      <el-table-column
        prop="dockingPerson"
        :show-overflow-tooltip="true"
        label="对接人"
      />
      <el-table-column
        label="操作"
        width="200px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="gotoEnterprise(row.id, 'edit')"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            class="mr10"
            type="primary"
            @click="gotoEnterprise(row.id, 'detail')"
          >
            <el-tooltip
              content="查看"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            class="mr10"
            @click="gotoEnterprise(row.id, 'auth')"
          >
            <el-tooltip
              content="授权"
              placement="top"
              effect="light"
            >
              <i class="el-icon-s-check" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="copy(row)"
          >
            <el-tooltip
              content="复制授权码"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document-copy" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            class="mr10"
            @click="downloadLicenseFile"
          >
            <el-tooltip
              content="下载资源文件"
              placement="top"
              effect="light"
            >
              <i class="el-icon-download" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delEnterprise(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination'
import { error, success } from '@core/utils/utils'
import { getAutonomyEnterprises, delAutonomyEnterprise, DownloadLicenseFile } from '@/api/enterprises/enterprises'

export default {
  components: {
    pagination,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    downloadLicenseFile() {
      DownloadLicenseFile()
    },
    copy(row) {
      const oInput = document.createElement('input')
      oInput.value = row.authorizationCode
      document.body.appendChild(oInput)
      // 选中输入框
      oInput.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
      oInput.remove()
    },
    gotoEnterprise(id, type) {
      this.$router.push({
        path: `/enterprises/${type}`,
        query: {
          id,
          type: 'auto',
        },
      })
    },
    hideTel(tel) {
      const reg = /^(\d{3})\d{4}(\d{4})$/
      return tel.replace(reg, '$1****$2')
    },
    delEnterprise(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const ids = [id]
        delAutonomyEnterprise({ ids }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      getAutonomyEnterprises(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
